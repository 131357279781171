import Helmet from "react-helmet"
import React from "react"
//import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import TyPageXPI from "src/components/typageXPI"
import Disclaimer from "@tightrope/disclaimers"

const TyData = import("./data/data.json");

export default function ThankYou() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
      @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');
     * {
    font-family: "Inria Sans", sans-serif;
     }
    
     
     @keyframes zoom-in-zoom-out {
       0% {
         transform: scale(1, 1);
       }
       25% {
         transform: scale(1, 1);
       }
       50% {
         transform: scale(1.25, 1.25);
       }
       75% {
         transform: scale(1, 1);
       }
       100% {
         transform: scale(1, 1);
       }
     }
    @media only screen and (max-width: 992px) {
      footer ul {
      flex-wrap:wrap;
    }
  }
}
    `}
    </style>
  
    <title>Thank you for downloading</title>
    </Helmet>
    <section>
      <TyPageXPI data={TyData}><Disclaimer></Disclaimer></TyPageXPI>
      </section>
      
    </>
  )
}
